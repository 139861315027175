import * as actionTypes from "../actions/types.js";
export default (state = { employeeSubscriberList: [],courseUserList:[],courseSubCategoryList:[],courseTopicList:[],viewTopicData:{},courseemplist:[],coursesublist:[],employeeCourselist:[] ,courseempviewlist:{}}, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_SUBSCRIBERS_LIST:
      return {
        ...state,
        employeeSubscriberList: action.payload ? action.payload : [],
      };
    case actionTypes.COURSE_USER_LIST:
      return {
        ...state,
        courseUserList: action.payload ? action.payload : [],
      };
    case actionTypes.COURSE_SUB_CATEGORY_LIST:
      return {
        ...state,
        courseSubCategoryList: action.payload ? action.payload : [],
      };
    case actionTypes.COURSE_SUB_TOPIC_LIST:
      return {
        ...state,
        courseTopicList: action.payload ? action.payload : [],
      };
    case actionTypes.COURSE_TOPIC_VIEW_DATA:
      return {
        ...state,
        viewTopicData: action.payload ? action.payload : {},
      };

      ///
    case actionTypes.COURSE_SUBSCRIBERS_LIST:
      return {
        ...state,
        coursesublist: action.payload ? action.payload : {},
      };
    case actionTypes.COURSE_EMPLOYEES_LIST:
      return {
        ...state,
        courseemplist: action.payload ? action.payload : {},
      };
    case actionTypes.COURSE_EMPLOYEES_VIEW_LIST:
      return {
        ...state,
        courseempviewlist: action.payload ? action.payload : {},
      };
    case actionTypes.COURSE_EMPLOYEES_COURSE_LIST:
      return {
        ...state,
        employeeCourselist: action.payload ? action.payload : [],
      };
    default:
      return state;
  }
};
